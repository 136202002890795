import axios from "axios";
export const getHomeData = {
  username: "Eveguel Arocha",
  jobtitle: [
    "Fullstack Developer",
    "Other Tech Stack I used...., NodeJs, ReactJs, VueJs, Mysql, MeteorJs, C#, PHP",
    "I may not be the best. But aim to be always the best",
  ],
  links: {
    facebook: "https://www.facebook.com/eveguel.arocha",
    linkedIn: "https://www.linkedin.com/in/eveguel-arocha-5718ab180/",
    instagram: "https://www.instagram.com/realdoyskie/",
    skype: "eveguelarocha77@gmail.com",
    youtube: "https://www.youtube.com/channel/UCBvypkiVnjQ_TVh-Fan8O0g",
  },
};
export const getAboutData = {
  description:
    "I've been so blessed working with the work I love(Programming). I give all the glorty to God, who always been there with me, He give strength and wisdom to overcome difficult task and giving me purpose on why i have this work, this skills, I would never be motivated as I am right now without God and His words guiding me.",
  short_description:
    "I primarily focus on Nodejs, React, Codeigniter(PHP), html, css and mysql as my database, I can also work with api configuration, web crawling, web hooks. I can also work with docker and server deployment",
  job_title: "Fullstack Developer (Mid Level)",
  birthday: "8th September 1997",
  website: "https://eveguelfreelancer.com",
  phone: "0917-975-9841",
  city: "Laoag City, Ilocos Norte, PH",
  age: "23",
  degree: "Bachelors",
  email: "eveguelfreelancer@gmail.com",
  freelance: "Available",
};

export const getResumeData = {
  username: "Eveguel Arocha",
  description: "5+ years in total of experience programming and developing.",
  phone: "0917-975-9841",
  city: "Laoag City, Ilocos Norte, PH",
  email: "eveguelfreelancer@gmail.com",
  education: {
    college: {
      course: "Bachelor of Science in Information Technology",
      year: "2014 - 2018",
      description:
        "The School of Information Technology mission is to provide competent instruction geared towards the development of competitive Information Technology (IT) professionals equipped with skills and strong moral values, thus becoming the preferred source of services and manpower in IT.",
      source: "http://www.dwcl.edu.ph/dwcms/school-of-nursing",
    },
    highSchool: {
      year: "2010 - 2014",
      description:
        "We, members of the Divine Word College of Laoag, a Catholic institution, envision ourselves to be a truly educative and evangelizing community able to face life’s challenges with dignity and actively participating in the upliftment of life.",
      source: "http://www.dwcl.edu.ph/dwcms/high-school",
    },
    school: "Divine Word College of Laoag, Ilocos Norte Ph.",
  },
  experience: {
    boolean: {
      position: "Fullstack developer",
      location:
        "Boolean - Review Software & Automation Consulting, (Homebased)",
      tasks: [
        "Build web application",
        "Create automation process",
        "Deploy updates to server",
        "Navigate Google cloud services for deployment",
        "PERN stack",
      ],
    },
    novare: {
      position: "Senior Software Engineer",
      location: "MDI Novare, Taguig, Philippines, (Homebased)",
      tasks: [
        "Fix bugs",
        "Create backend code for new feature functionlity",
        "Deploy updates to server",
        "Navigate AWS, uses S3, lambda services",
        "Uses python language for coding",
      ],
    },
    ourhiraya: {
      position: "Fullstack Developer",
      location: "Ourhiraya, (Homebased)",
      tasks: [
        "Developer web app for internal business",
        "We use React,Nodejs, Mysql for development, and github for version control and collaboration",
        "Deploy updates to server, frontend and backend code",
        "Attend stand up meetings for updates",
      ],
    },
    a2bhq: {
      position: "Web Developer/ Programmer",
      location: "A2BHQ, California, CA (Homebased)",
      tasks: [
        "Coding, creating automation to business processes faster, designing simple UI/UX, Web Crawling, api configurations",
        "Fix errors on existing codes, reuse functions, problem solving",
        "Attend daily meetings, provide daily updates, advise co-worker for solution, train new hired person",
        "Document development processes",
      ],
    },
    freelance: {
      position: "Web Developer",
      location: "Freelance",
      tasks: [
        "Coding, Desiging Web layouts using mobirise 4, Api configuration",
        "Embed Messenger chatbox",
        "Monitor and maintain web stability",
      ],
    },
    alorica: {
      position: "Customer Service Representative",
      location: "Alorica, San Nicolas, Ilocos Norte, PH",
      tasks: [
        "Answering calls",
        "Assisting customer with credit card issues and payment",
      ],
    },
  },
};

export const setCSRF = async (indicator, X, Y, Z) => {
  return new Promise(async (resolve, reject) => {
    if (indicator === 1 || indicator === 3) {
      let { data } = await axios("https://abishai.tech/token");
      axios.defaults.headers.post["X-CSRF-Token"] = data.csrfToken;
      axios.defaults.withCredentials = true;
    } else if (indicator === 2 || indicator === 4) {
      if (X !== "" && Y !== "" && Z !== "") {
        let { data } = await axios("https://abishai.tech/token");
        axios.defaults.headers.post["X-CSRF-Token"] = data.csrfToken;
        axios.defaults.withCredentials = true;
      }
    }
    resolve(true);
  });
};
